import { css } from 'goober';
import { PostTypeGroups, PostTypeList } from '../dialogs/postingDlg';
import { Posting, OrgListing } from '../components/posting';
import { geo } from '../services/geoService';
import { state } from '../services/state';
import { settings, user_settings } from '../services/settings';

const c_filter = css`
	label {
		margin-right: 4px;
		font-size: 14px;
		font-weight: 500;
		color: var(--page-fg-color);
	}
`;

async function changeZip() {
	const zip = prompt('Enter your zip:');
	if (zip) {
		const loc = await geo.get_loc_from_zip(zip);
		if (loc) {
			geo.set_location(loc);
		}
		state.refreshList();
	}
}

function Filters() {
	return (
		<div id="post_filters">
			{geo.loc && (
				<div class="flex center">
					<select
						value={state.filter.type_group}
						onChange={(e) => {
							state.filter.type_group = e.target.value;
							user_settings.group_filter = e.target.value;
							settings.save();
						}}
					>
						{PostTypeGroups.map((t) => (
							<option value={t.name}>{t.name}</option>
						))}
					</select>
					&nbsp;
					<h3 style={{ margin: 0 }}>
						near {geo.loc.name} (<a onClick={changeZip}>{geo.loc.zip_code}</a>)
					</h3>
				</div>
			)}
			<span class="grow"></span>
			<select
				value={geo.range_miles}
				onChange={(e) => {
					geo.range_miles = parseInt(e.target.value);
					state.refreshList();
				}}
			>
				<option value={25}>25 miles</option>
				<option value={50}>50 miles</option>
				<option value={100}>100 miles</option>
				<option value={200}>200 miles</option>
				<option value={500}>500 miles</option>
			</select>
			{/* <div class={c_filter}>
				<label>Type</label>
				<select
					value={state.filter.type}
					onChange={(e) => (state.filter.type = parseInt(e.target.value) || null)}
				>
					<option value={null}>All</option>
					{PostTypeList.map((t) => (
						<option value={t.type}>{t.name}</option>
					))}
				</select>
			</div> */}
			<div class={c_filter}>
				<label>Tag</label>
				<select
					value={state.filter.tag || ''}
					onChange={(e) => {
						state.filter.tag = e.target.value || null;
					}}
				>
					<option value={''}>All</option>
					{state.tags.map((t) => (
						<option>{t}</option>
					))}
				</select>
			</div>
		</div>
	);
}

function Postings() {
	state.show_user_bar = true;
	return (
		<div id="app_content">
			<Filters />

			{state.f_posts.map((p) => (
				<Posting p={p} />
			))}
			{state.f_local_orgs.map((o) => (
				<OrgListing o={o} />
			))}
			{!state.f_local_orgs.length && !state.f_posts.length && (
				<div style={{ 'margin-top': '20px' }}>
					{state.loading ? 'Loading...' : 'No Posts or Organizations Found'}
				</div>
			)}
		</div>
	);
}

export function HomePage() {
	return <Postings />;
}
