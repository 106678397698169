import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { state } from '../services/state';
import { openToast } from './toast';
import { format_phone, removeFromArray } from '../utils/utils';
import { geo } from '../services/geoService';
import { make_req } from '../services/api2';

function SubscriptionsDlg() {
	let el: HTMLDivElement;
	let l = makeAutoObservable({
		subscriptions: [] as ISubscription[]
	});

	make_req('get_subscriptions', { user_id: state.user.id }).then((r: ISubscription[]) => {
		l.subscriptions = r;
	});

	async function add_loc() {
		let r = prompt('Enter zip code');
		if (!r) return;
		let loc = await geo.get_loc_from_zip(r);
		if (!loc) {
			openToast('invalid zip code', true);
			return;
		} else {
			let sub: ISubscription = { user_id: state.user.id, loc_id: loc.id, range: 25 };
			await make_req('save_subscription', sub);
			sub.location = loc;
			l.subscriptions.push(sub);
		}
	}

	async function delete_sub(sub: ISubscription) {
		if (!confirm('Are you sure you want to delete this subscription?')) return;
		await make_req('delete_subscription', sub);
		removeFromArray(sub, l.subscriptions);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header">Notification</div>
				<div class="dlg-content">
					<p>
						Recieve text notifications to {format_phone(state.user.phone)} when help wanted posts are
						created for these locations
					</p>
					<div class="row" style={{ position: 'relative' }}>
						<div class="flex">
							<label class="rowlabel grow">Locations</label>
							<a style={{ 'font-size': '14px' }} onClick={(e) => add_loc()}>
								Add Location
							</a>
						</div>
						<table style={{ width: '100%', border: '1px dashed', 'font-size': '14px' }}>
							<tbody>
								{l.subscriptions?.map((o) => (
									<tr>
										<td>{o.location.name}</td>
										<td>range: {o.range}</td>
										<td>
											<a class="danger" onClick={(e) => delete_sub(o)}>
												Delete
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
						{!l.subscriptions?.length && <div>none</div>}
					</div>
				</div>
				<div class="dlg-footer">
					<button id="bt_subscriptions_dlg_close" onClick={(e) => dlg.close(el)}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export function openSubscriptionsDlg() {
	return dlg.open(SubscriptionsDlg, {});
}
