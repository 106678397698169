import { makeAutoObservable } from 'mobx';
import { api } from './api';

interface IIpApiResult {
	city: 'Klamath Falls';
	country: 'United States';
	countryCode: 'US';
	isp?: 'Charter Communications';
	lat: 42.2957;
	lon: -121.8326;
	org?: 'Spectrum';
	query?: '47.36.174.245';
	region?: 'OR';
	regionName?: 'Oregon';
	status?: 'success';
	timezone?: 'America/Los_Angeles';
	zip: '97601';
}

interface IIpGeoResult {
	city: string;
	zipcode: string;
	latitude: string;
	longitude: string;
}

interface IGmapsResult {
	results: [
		{
			geometry: {
				location: { lat: number; lng: number };
			};
			formatted_address: string;
		}
	];
}

interface IZipCodeApiResult {
	zip_code: string;
	lat: number;
	lng: number;
	city: string;
	state: string;
}

export function get_loc_rect(miles: number, lat: number, lng: number): IGeoRect {
	const MILES_PER_DEG = 69;
	const RADIUS_DEG = miles / MILES_PER_DEG;

	return {
		lat_min: lat - RADIUS_DEG,
		lat_max: lat + RADIUS_DEG,
		long_min: lng - RADIUS_DEG,
		long_max: lng + RADIUS_DEG
	};
}

class GeoService {
	range_miles = 50;
	loc: ILocation = null;

	constructor() {
		makeAutoObservable(this);
	}

	get rect() {
		return this.loc && get_loc_rect(this.range_miles, this.loc.lat, this.loc.lng);
	}

	async init(): Promise<IGeoInfo> {
		let info = localStorage.getItem('geo-info');
		if (info) {
			return JSON.parse(info);
		} else {
			let r = await geo.locate();
			return r;
			//if (r) await this.setInfo(r);
		}
	}

	async set_location(loc: ILocation) {
		this.loc = loc || null;
		if (loc) {
			let info: IGeoInfo = { zip: loc.zip_code, lat: loc.lat, lng: loc.lng, city: loc.name };
			localStorage.setItem('geo-info', JSON.stringify(info));
		}
	}

	/*locate(): Promise<IGeoInfo> {
		return fetch('http://ip-api.com/json/?fields=city,lat,lon,zip').then((r) =>
			r.json().then((r: IIpApiResult) => {
				this.info = {
					city: r.city,
					zip: r.zip,
					lat: r.lat,
					lng: r.lon
				};
				return this.info;
			})
		);
	}*/

	async locate(): Promise<IGeoInfo> {
		const url = 'https://api.ipgeolocation.io/ipgeo?apiKey=fb073f6f50864cfaaff31fc266fb4c7d';
		let r: IIpGeoResult = await fetch(url).then((r) => r.json());
		return (
			r && {
				city: r.city,
				zip: r.zipcode,
				lat: parseFloat(r.latitude),
				lng: parseFloat(r.longitude)
			}
		);
	}

	/*coordsFromZip(zip: string, set: boolean = true): Promise<IGeoInfo> {
		const api_key = 'AIzaSyA4ah7FaA7GlvUrz-DCpzhbIZmQynM3SuI';
		let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${api_key}&components=postal_code:${zip}`;
		return fetch(url).then((r) =>
			r.json().then((r: IGmapsResult) => {
				if (!r.results.length) {
					return null;
				}
				const rr: IGeoInfo = {
					city: r.results[0].formatted_address,
					zip,
					lat: r.results[0].geometry.location.lat,
					lng: r.results[0].geometry.location.lng
				};
				if (set) {
					this.setInfo(rr);
				}
				return rr;
			})
		);
	}*/

	async coordsFromZip(zip: string): Promise<IGeoInfo> {
		const api_key = 'js-9aHX6NvlNTSxZpSy3XApGLt70WAWGhr2qR99sYYfJAtVIYjlaA62tAG7v4A58kEI';

		let url = `https://www.zipcodeapi.com/rest/${api_key}/info.json/${zip}/degrees`;
		let r: IZipCodeApiResult = await fetch(url).then((r) => r.json());

		if (!r.zip_code) {
			return null;
		}
		const rr: IGeoInfo = {
			city: r.city,
			zip,
			lat: r.lat,
			lng: r.lng
		};
		return rr;
	}

	async get_loc_from_zip(zip: string): Promise<ILocation> {
		let loc = (await api.get_locations({ zip_code: zip }))[0];
		if (!loc) {
			let info = await this.coordsFromZip(zip);
			if (info) {
				loc = await api.create_location(info);
			}
		}
		return loc;
	}
}

export const geo = new GeoService();

//@ts-ignore
window.geo = geo;
