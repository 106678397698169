import { dlg } from '../dlg';
import { For } from 'solid-js';
import { state } from '../services/state';
import { openOrg } from './orgDlg';
import { removeFromArray } from '../utils/utils';
import { api } from '../services/api';
import { openOrgSelectBox } from './selectDlg';
import { make_req } from '../services/api2';

function UserOrgListDlg() {
	let el: HTMLDivElement;

	async function edit_org(o: IOrg) {
		await openOrg(o);
		if (!o.id) {
			removeFromArray(o, state.user_orgs);
		}
	}

	async function set_org_context(o?: IOrg) {
		const r = await api.get_org_context(o?.id);
		if (r) state.set_org(r);
		dlg.close(el);
	}

	async function claim_org() {
		const r = await openOrgSelectBox('Search for organization');
		if (!r) return;
		await make_req('claim_org', r.id);
		state.refresh_my_orgs();
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">Select Organization Context</h4>
				<div class="dlg-content">
					<table>
						<tbody>
							<tr>
								<td>No organization</td>
								<td>
									<a onClick={(e) => set_org_context(null)}>Select</a>
								</td>
								<td></td>
							</tr>

							<For each={state.user_orgs}>
								{(o) => (
									<tr>
										<td>{o.name}</td>
										<td>
											<a onClick={(e) => set_org_context(o)}>Select</a>
										</td>
										<td>
											<a onClick={(e) => edit_org(o)}>Edit</a>
										</td>
									</tr>
								)}
							</For>
						</tbody>
					</table>
				</div>
				<div class="dlg-footer">
					<button
						onClick={(e) => {
							dlg.close(el);
							openOrg(null);
						}}
					>
						Create Organization
					</button>
					<button onClick={claim_org}>Request Access</button>
					<span class="grow" />
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openUserOrgList() {
	return dlg.open(UserOrgListDlg, {});
}
