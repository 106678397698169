import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { geo } from '../services/geoService';
import { state } from '../services/state';
import { autofocus } from '../utils/autofocus';
import { NewPostDlg } from './newPostDlg';
import {
	add_days,
	create_random_pin,
	date_str_iso,
	formateDateStr,
	get_loc_desc,
	getRandomInt,
	raw_phone_number
} from '../utils/utils';
import { TagBox } from '../components/tagbox';
import { openToast } from './toast';

export enum PostType {
	help_wanted = 1,
	help_offered = 2,
	org_info = 3,
	event = 4
}

interface IPostType {
	type: PostType;
	name: string;
	color: string;
	special?: boolean;
	can_action?: boolean;
}

export const PostTypeList: IPostType[] = [
	{
		type: PostType.help_wanted,
		name: 'Help Wanted',
		color: 'aqua',
		can_action: true
	},
	{
		type: PostType.help_offered,
		name: 'Help Offered',
		color: 'lime'
	},
	{
		type: PostType.org_info,
		name: 'Organization',
		color: 'orange',
		special: true
	},
	{
		type: PostType.event,
		name: 'Event',
		color: 'orchid'
	}
];
export const PostTypeMap = new Map<PostType, IPostType>();
PostTypeList.forEach((t) => PostTypeMap.set(t.type, t));

interface IPostTypeGroup {
	name: string;
	types: Set<PostType>;
}

export const PostTypeGroups: IPostTypeGroup[] = [
	{
		name: 'Help Offered',
		types: new Set([PostType.help_offered, PostType.event, PostType.org_info])
	},
	{
		name: 'Help Wanted',
		types: new Set([PostType.help_wanted])
	}
];

function PostingDlg(z: { t: IPost }) {
	let el: HTMLDivElement;

	const is_new = !z.t.id;

	const l = makeAutoObservable({ post: { ...z.t } });
	const p = l.post;
	p.tags = [...z.t.tags];

	async function save() {
		if (!p.name || !p.description || !p.phone || !p.title) {
			openToast('Name, title, description, and phone number are required', true);
			return;
		}

		const thenum = raw_phone_number(p.phone)?.toString();
		if (thenum.length !== 10) {
			openToast('Phone number must have 10 digits', true);
			return;
		}

		let pin: string = undefined;
		// find or create user
		if (!p.user_id) {
			let found = await api.get_users({ phone: p.phone });
			if (found.length > 0) {
				p.user = found[0];
				p.user_id = p.user.id;
			} else {
				pin = create_random_pin();
				p.user = { name: p.name, phone: p.phone, email: null, pin, provider: false };
				await api.save_user(p.user);
				p.user_id = p.user.id;
			}
		}

		await api.save_post(p);
		Object.assign(z.t, p);
		dlg.close(el, z.t);

		if (is_new) {
			const edit_url = document.location.origin + `?id=${p.id}`;
			//window.history.replaceState(null, null, edit_url);
			await dlg.open(NewPostDlg, { edit_url, pin });
		}
	}

	async function deletePosting() {
		if (!confirm('Are you sure you want to delete your posting?')) return;
		await api.delete_post(z.t);
		z.t.id = null;
		dlg.close(el);
		state.refreshList();
	}

	async function change_location() {
		let r = prompt('Enter zip code');
		if (!r) return;
		let loc = await geo.get_loc_from_zip(r);
		if (!loc) {
			openToast('invalid zip code', true);
			return;
		} else {
			p.loc_id = loc.id;
			p.location = loc;
		}
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">{is_new ? 'Create New Posting' : 'Edit Posting'}</h4>
				<div class="dlg-content">
					<div class="row">
						<label>Post Type</label>
						<select value={p.type} onChange={(e) => (p.type = parseInt(e.target.value))}>
							{PostTypeList.filter((t) => !t.special).map((t) => (
								<option value={t.type}>{t.name}</option>
							))}
						</select>
					</div>
					<div class="row">
						<label>Location</label>
						<div class="flex gap10">
							<input class="grow" value={get_loc_desc(p.location)} readOnly={true} />
							<button id="bt_post_change_loc" onClick={change_location}>
								Change
							</button>
						</div>
					</div>
					<div class="row">
						<label>Your Name</label>
						<input
							maxLength={30}
							value={p.name}
							onInput={(e) => (p.name = e.target.value)}
							ref={(e) => [autofocus(e)]}
						/>
					</div>
					<div class="row">
						<label>Phone Number</label>
						<input maxLength={20} value={p.phone} onInput={(e) => (p.phone = e.target.value)} />
					</div>
					<div class="row">
						<label>Title</label>
						<input maxLength={80} value={p.title} onInput={(e) => (p.title = e.target.value)} />
					</div>
					<div class="row">
						<label>Description</label>
						<textarea
							maxLength={200}
							style={{ resize: 'none' }}
							rows={3}
							value={p.description}
							onInput={(e) => (p.description = e.target.value)}
						/>
					</div>
					<div class="row">
						<label>Tags</label>
						<TagBox tags={p.tags} />
					</div>
					{p.expire_date && (
						<div class="row">
							<label>Expiration Date: {formateDateStr(p.expire_date)}</label>
							{/* <input
							type="date"
							value={date_str_iso(l.expire_date)}
							onChange={(e) => (l.expire_date = e.target.value)}
						/> */}
						</div>
					)}
				</div>
				<div class="dlg-footer">
					{!is_new && (
						<button id="bt_post_delete" class="danger" onclick={deletePosting}>
							Delete
						</button>
					)}
					<span class="grow"></span>
					<button id="bt_post_save" class="primary" onClick={save}>
						{is_new ? 'Create' : 'Save'}
					</button>
					<button id="bt_post_cancel" onClick={(e) => dlg.close(el)}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

export function openPostingDlg(t?: IPost): Promise<IPost> {
	if (!t) {
		t = {
			user_id: state.user?.id,
			user: state.user || null,
			name: state.user?.name || null,
			phone: state.user?.phone || null,
			title: '',
			description: '',
			type: PostType.help_wanted,
			post_date: new Date().toDateString(),
			expire_date: add_days(new Date(), 6).toDateString(),
			update_date: new Date().toDateString(),
			archived: false,
			action_count: 0,
			pending_action: false,
			tags: [],
			org_id: state.user_org?.id || null,
			loc_id: geo.loc.id,
			location: { ...geo.loc }
		};
	}
	return dlg.open(PostingDlg, { t });
}
