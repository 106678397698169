import { openAccountDlg } from '../dialogs/accountDlg';
import { openClientListDlg } from '../dialogs/clientListDlg';
import { openGuidelines } from '../dialogs/guidelines';
import { openHistory } from '../dialogs/history';
import { openInbox } from '../dialogs/inbox';
import { openLoginDlg } from '../dialogs/loginDlg';
import { openPostingDlg } from '../dialogs/postingDlg';
import { openSubscriptionsDlg } from '../dialogs/subscriptionsDlg';
import { openUserOrgList } from '../dialogs/userOrgListDlg';
import { openUserPostings } from '../dialogs/userPostings';
import { dlg } from '../dlg';
import { state } from '../services/state';

export function Navbar() {
	return (
		<div id="title">
			<div class="inner flex gap10 center">
				<img class="large" src="/icons8-help-50.png" />
				<div style={{ 'font-size': '20px' }}>Bit of Help</div>
				{/* <span style={{ color: 'aqua' }}>- where people help people</span> */}
				<span class="grow" />

				{!state.has_user && (
					<a style={{ color: 'white' }} onClick={openLoginDlg}>
						Sign In
					</a>
				)}

				<a
					style={{
						'font-size': '24px',
						'text-decoration': 'none',
						color: state.dark ? 'white' : 'yellow',
						'margin-bottom': '4px'
					}}
					onClick={(e) => state.set_theme(true)}
				>
					☀
				</a>

				<a class="large" onClick={openGuidelines}>
					Guidelines
				</a>

				<button
					id="bt_create_posting"
					onClick={async (e) => {
						let p = await openPostingDlg(null);
						if (p) state.refreshList();
					}}
				>
					Create Posting
				</button>
				{/* <button
					onClick={(e) => {
						let id = prompt('Enter post id:');
						if (id) {
							state.editPost(id);
						}
					}}
				>
					Edit Posting
				</button> */}
			</div>
		</div>
	);
}

export function UserBar() {
	return (
		<div id="org_bar">
			<div class="inner">
				{state.is_provider && <a onClick={(e) => openClientListDlg()}>Clients</a>}
				{state.is_provider && <a onClick={(e) => openHistory()}>Actions</a>}

				<a onClick={(e) => openUserPostings()}>Edit Posts</a>

				{state.is_provider && (
					<a
						onClick={(e) => {
							openUserOrgList();
						}}
					>
						{state.user_org?.name ||
							(state.user_orgs.length ? 'Select Organization' : 'Create Organization')}
					</a>
				)}

				{state.is_provider && (
					<a
						onClick={(e) => {
							openSubscriptionsDlg();
						}}
					>
						Notifications
					</a>
				)}

				{state.inbox.length && <a onClick={(e) => openInbox()}>Inbox ({state.inbox.length})</a>}

				{state.sys_admin && (
					<a class="large" style={{ color: 'fuchsia' }} href="/admin">
						Admin
					</a>
				)}

				<span class="grow"></span>
				<a class="large" onClick={(e) => openAccountDlg(state.user)}>
					{state.user.name}
				</a>
				<a onClick={(e) => state.signOut()}>Sign Out</a>
			</div>
		</div>
	);
}
