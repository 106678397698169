import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { geo } from '../services/geoService';
import { state } from '../services/state';
import { get_loc_desc, raw_phone_number, removeFromArray, removeSpecialCharacters } from '../utils/utils';
import { autofocus } from '../utils/autofocus';
import { TagBox } from '../components/tagbox';
import { openToast } from './toast';
import { sharing } from '../services/sharing';
import { openOrgSelectBox } from './selectDlg';
import { make_req } from '../services/api2';

const valid_org_path = 'abcdefghijklmnopqrstuvwxyz';

enum OrgTab {
	general,
	providers,
	sharing
}

interface IShare {
	org_id: number;
	name: string;
	me_share: IOrgOrg;
	they_share: IOrgOrg;
}

class LocalState {
	o: IOrg = null;
	tab = OrgTab.general;
	shares = [] as IShare[];
	users = [] as IOrgUser[];

	constructor() {
		makeAutoObservable(this);
	}

	get is_new() {
		return !this.o?.id;
	}

	async remove_share(oo: IShare) {
		if (!oo.they_share) {
			if (!confirm(`Are you sure you want to remove ${oo.name}?`)) return;
		}
		await api.delete_org_org(oo.me_share);
		oo.me_share = null;
		if (!oo.me_share && !oo.they_share) {
			removeFromArray(oo, this.shares);
		}
	}

	async toggle_sharing(share: IShare) {
		if (share.me_share) {
			await this.remove_share(share);
			return;
		} else {
			let oo: IOrgOrg = { org_id: this.o.id, sub_org_id: share.org_id };
			await api.save_org_org(oo);
			share.me_share = oo;
		}
	}

	async add_provider_by_email() {
		let email = prompt('Enter user email');
		if (email) {
			email = email.toLowerCase().trim();
			let r = await api.get_users({ email });
			if (r.length === 0) {
				let c = confirm('No provider found with that email, would you like to create a new one?');
				if (c) {
				}
			} else if (r.length > 1) {
				openToast('multiple providers found with that email', true);
			}
			if (r.length === 1) {
				await this.add_user(r[0]);
			}
		}
	}

	async add_user(user: IUser) {
		let ou: IOrgUser = { org_id: this.o.id, user_id: user.id, admin: false, user };
		await api.save_org_user(ou);
		this.users.push(ou);
	}

	async remove_provider(p: IOrgUser) {
		if (!confirm(`Are you sure you want to remove ${p.user.name} from ${this.o.name}?`)) return;
		if (this.users.length <= 1) {
			openToast('At least one user is required', true);
			return;
		}
		removeFromArray(p, this.users);
		api.delete_org_user(p);
	}
}

function SharingTab(p: { l: LocalState }) {
	const l = p.l;
	const o = p.l.o;

	async function add_org() {
		let o = await openOrgSelectBox();
		if (o) {
			let share: IShare = { org_id: o.id, name: o.name, me_share: null, they_share: null };
			await l.toggle_sharing(share);
			if (share.me_share?.id) {
				l.shares.push(share);
			}
		}
	}

	return (
		<div>
			<p style={{ 'margin-top': 0 }}>
				Select which providers can view this organization's client and action data. Only providers within this
				organization can change the data.
			</p>
			<div class="row">
				<label>Sharing Policy</label>
				<select value={o.share_policy} onChange={(e) => (o.share_policy = parseInt(e.target.value))}>
					{sharing.map((s) => (
						<option value={s.id}>{s.name}</option>
					))}
				</select>
			</div>
			<div class="row" style={{ position: 'relative' }}>
				<div class="flex">
					<label class="rowlabel grow">Sharing per Organization</label>
					<a style={{ 'font-size': '14px' }} onClick={(e) => add_org()}>
						Add Org
					</a>
				</div>
				<table style={{ width: '100%', border: '1px dashed', 'font-size': '14px' }}>
					<tbody>
						{l.shares?.map((o) => (
							<tr>
								<td>{o.name}</td>
								<td>
									<label>
										Share Data&nbsp;
										<input
											type="checkbox"
											checked={!!o.me_share}
											onChange={(e) => l.toggle_sharing(o)}
										/>
									</label>
								</td>
								<td>{o.they_share ? 'They Share' : 'They do NOT share'}</td>
							</tr>
						))}
					</tbody>
				</table>
				{!l.shares?.length && <div>none</div>}
				<br />
				<div style={{ 'font-size': '14px', color: 'navy', 'margin-bottom': '10px' }}>
					* Sharing will propogate to nested organizations
				</div>
			</div>
		</div>
	);
}

function OrgProviders(p: { l: LocalState }) {
	const l = p.l;
	const o = p.l.o;

	return (
		<div class="row">
			<div class="flex">
				<label class="rowlabel grow">Providers</label>
				<a style={{ 'font-size': '14px' }} onClick={(e) => l.add_provider_by_email()}>
					Add User
				</a>
			</div>
			<table style={{ width: '100%', border: '1px dashed', 'font-size': '14px' }}>
				<tbody>
					{l.users?.map((ou) => (
						<tr>
							<td>{ou.user.email}</td>
							<td>{ou.user.name}</td>
							<td>{ou.user.phone}</td>
							<td>
								<a
									onClick={(e) => {
										ou.admin = !ou.admin;
										api.save_org_user(ou);
									}}
								>
									{ou.admin ? 'admin' : 'non-admin'}
								</a>
							</td>
							<td class="min-td">
								<a onclick={(e) => l.remove_provider(ou)} class="small danger">
									Remove
								</a>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{!l.users?.length && <div>none</div>}
		</div>
	);
}

function OrgDlg(p: { o: IOrg }) {
	let el: HTMLDivElement;

	const was_selected = p.o.id === state.user_org?.id;

	const l = new LocalState();
	l.o = p.o;
	const o = l.o;

	if (!l.is_new) {
		api.get_org_users(o.id).then((r) => {
			l.users = r;
		});

		api.get_sharing_orgs(o.id).then((r) => {
			let map = new Map<number, IShare>();
			for (let oo of r) {
				let is_me = oo.org_id === l.o.id;
				let org_id = is_me ? oo.sub_org_id : oo.org_id;
				let f = map.get(org_id);
				if (!f) {
					f = makeAutoObservable({ org_id, name: oo.org.name, me_share: null, they_share: null });
					l.shares.push(f);
					map.set(org_id, f);
				}
				if (is_me) {
					f.me_share = oo;
				} else {
					f.they_share = oo;
				}
			}
		});
	}

	async function change_tab(t: OrgTab) {
		if (l.is_new) {
			if (!confirm('You must save pending changes first, continue?')) return;
			await save(false);
		}
		if (l.o.id) l.tab = t;
	}

	async function delete_org() {
		if (!confirm('Are you sure you want to delete this organization? All related data will be deleted.')) return;

		while (l.shares.length) {
			let u = l.shares.pop();
			if (u.me_share) await api.delete_org_org(u.me_share);
			if (u.they_share) await api.delete_org_org(u.they_share);
		}

		while (l.users.length) {
			let u = l.users.pop();
			await api.delete_org_user(u);
		}

		await api.delete_org(l.o);
		p.o.id = null;
		if (was_selected) {
			state.set_org(null);
		}

		dlg.close(el);
	}

	async function change_location() {
		let r = prompt('Enter zip code');
		if (!r) return;
		let loc = await geo.get_loc_from_zip(r);
		if (!loc) {
			openToast('invalid zip code', true);
			return;
		} else {
			o.loc_id = loc.id;
			o.location = loc;
		}
	}

	async function save(close = true) {
		if (!o.name) {
			openToast('Name is required', true);
			return;
		}

		o.path = removeSpecialCharacters(o.name.toLowerCase());

		await api.save_org(o);
		Object.assign(p.o, o);
		state.refreshList();
		state.refresh_my_orgs();
		if (close) {
			dlg.close(el);
		}
	}

	function getUrl() {
		let url = location.protocol + '//' + location.host + '/org/' + o.path;
		return url;
	}

	/*async function unclaim() {
		if (!confirm('Are you sure you want to unclaim this organization?')) return;
		await make_req('unclaim_org', o.id);
		dlg.close(el);
		p.o.id = null;
		if (was_selected) {
			state.set_org(null);
		}
	}*/

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '600px', height: '500px' }}>
				<div class="dlg-header gap20">
					<span>Organization</span>
					<a classList={{ active: l.tab === OrgTab.general }} onClick={(e) => change_tab(OrgTab.general)}>
						General
					</a>
					<a classList={{ active: l.tab === OrgTab.providers }} onClick={(e) => change_tab(OrgTab.providers)}>
						Providers
					</a>
					<a classList={{ active: l.tab === OrgTab.sharing }} onClick={(e) => change_tab(OrgTab.sharing)}>
						Sharing
					</a>
					{/* <a classList={{ active: l.tab === OrgTab.policy }} onClick={(e) => change_tab(OrgTab.policy)}>
						Policy
					</a> */}
				</div>
				<div class="dlg-content">
					{l.tab === OrgTab.general && (
						<div>
							<div class="row">
								<label>Name</label>
								<input
									value={o.name}
									onChange={(e) => (o.name = e.target.value)}
									ref={(e) => [autofocus(e)]}
								/>
							</div>
							<div class="row">
								<label>Website (url)</label>
								<div class="flex gap10">
									<input
										class="grow"
										value={o.website}
										onChange={(e) => (o.website = e.target.value)}
									/>
								</div>
							</div>
							{/* <div class="row">
								<label>Path (lowercase a - z)</label>
								<input value={o.path} onInput={(e) => (o.path = e.target.value)} />

								{o.path && (
									<input
										style={{
											'font-size': '14px',
											color: 'blue',
											background: 0,
											border: 0
										}}
										readonly
										value={getUrl()}
									/>
								)}
							</div> */}
							<div class="row">
								<label>Zip Code</label>
								<div class="flex gap10">
									<input class="grow" value={get_loc_desc(o.location)} readOnly={true} />
									<button id="bt_org_change_loc" onClick={change_location}>
										Change
									</button>
								</div>
							</div>
							<div class="row">
								<label>Description (optional)</label>
								<textarea
									style={{ resize: 'none' }}
									value={o.description}
									rows={3}
									onChange={(e) => (o.description = e.target.value)}
								></textarea>
							</div>
							<div class="row">
								<label>Tags</label>
								<TagBox tags={o.tags} />
							</div>
							<div class="row">
								<label>Phone Number (optional)</label>
								<input
									value={o.phone}
									onChange={(e) => {
										o.phone = e.target.value;
									}}
								/>
							</div>
							<div class="row">
								<label>Address (optional)</label>
								<textarea
									style={{ resize: 'none' }}
									value={o.address}
									rows={3}
									onChange={(e) => (o.address = e.target.value)}
								></textarea>
							</div>
							<div class="row">
								<label>
									<input
										type="checkbox"
										checked={o.discover}
										onChange={(e) => (o.discover = !o.discover)}
									></input>
									&nbsp;Discoverable
								</label>
								{/* <label>
									<input type="checkbox" checked={true}></input>&nbsp;Active
								</label> */}
							</div>
						</div>
					)}

					{l.tab === OrgTab.providers && <OrgProviders l={l} />}
					{l.tab === OrgTab.sharing && <SharingTab l={l} />}
				</div>
				<div class="dlg-footer">
					{!l.is_new && (
						<button id="bt_org_delete" class="danger" onClick={delete_org}>
							Delete
						</button>
					)}
					{/* {state.sys_admin && !l.is_new && <button onClick={unclaim}>Unclaim</button>} */}
					<span class="grow" />
					<button id="bt_org_save" class="primary" onClick={(e) => save(true)}>
						{l.is_new ? 'Create' : 'Save'}
					</button>
					<button id="bt_org_cancel" onClick={(e) => dlg.close(el)}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

export function openOrg(o: IOrg) {
	if (!o) {
		o = {
			name: '',
			path: '',
			website: null,
			description: null,
			phone: null,
			address: null,
			loc_id: geo.loc.id,
			badges: [],
			tags: [],
			share_policy: sharing[0].id,
			location: geo.loc,
			verified: false,
			discover: true
		};
	}
	return dlg.open(OrgDlg, { o });
}
