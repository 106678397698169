import { makeAutoObservable } from 'mobx';
import { openOrg } from '../dialogs/orgDlg';
import { api } from '../services/api';
import { state, state_init } from '../services/state';
import { BackHomeBar } from '../components/backHome';
import { showMessage } from '../dialogs/message';
import { make_req } from '../services/api2';
import { config } from '../services/config';

export function AdminPage() {
	const l = makeAutoObservable({
		orgs: [] as IOrg[],
		summary: {} as any
	});

	api.get_orgs({ verified: false }).then((r) => (l.orgs = r));

	state_init.then((r) => {
		make_req('get_admin_summary').then((r) => (l.summary = r));
	});

	state.show_user_bar = false;

	async function verify(o: IOrg) {
		await make_req('verify_org', o.id);
		o.verified = true;
	}

	const admin_panel = (
		<div class="grow">
			<BackHomeBar />
			<div class="inner" style={{ 'padding-top': '20px' }}>
				<h2>Admin Panel</h2>
				<h4>
					Version: <span style={{ color: 'crimson' }}>{config.version}</span>
				</h4>
				<br />
				<div class="flex gap20">
					<button
						onClick={async (e) => {
							let summary: ICronSummary = await make_req('run_cron');
							showMessage(JSON.stringify(summary, null, 2));
						}}
					>
						Run Cron Job
					</button>
				</div>
				<br />
				<h4>Summary</h4>
				{l.summary && <div>{JSON.stringify(l.summary)}</div>}
				<br />
				<h4>Organizations</h4>
				<table>
					{l.orgs.map((o) => (
						<tr>
							<td>
								<a
									onClick={(e) => {
										openOrg(o);
									}}
								>
									{o.name}
								</a>
							</td>
							<td>
								{o.verified ? (
									'verified'
								) : (
									<a style={{ color: 'magenta' }} onClick={(e) => verify(o)}>
										verify now
									</a>
								)}
							</td>
						</tr>
					))}
				</table>
			</div>
		</div>
	);

	return <>{state.sys_admin && admin_panel}</>;
}
