export enum SettingType {
	main = 'bof_settings'
}

export const user_settings = {
	theme: null as string,
	session_id: null as string,
	org_id: null as string,
	group_filter: 'Help Offered',
	first_time: true
};

class Settings {
	_get<T>(type: SettingType, defaultValue?: T): T {
		let result: any = localStorage.getItem(type);

		if (result == undefined) {
			result = defaultValue;
		} else {
			result = JSON.parse(result);
		}

		return <T>result;
	}

	_set(type: SettingType, obj: any) {
		if (obj.toLowerCase === undefined) {
			obj = JSON.stringify(obj);
		}
		localStorage.setItem(type, obj);
	}

	load() {
		let found = settings._get(SettingType.main);
		if (found) {
			Object.assign(user_settings, found);
			user_settings.first_time = false;
		}
	}

	save() {
		this._set(SettingType.main, user_settings);
	}
}

export const settings = new Settings();
// @ts-ignore
window.settings = settings;
//@ts-ignore
window.user_settings = user_settings;
