import { css } from 'goober';
import { openPostingDlg, PostType, PostTypeMap } from '../dialogs/postingDlg';
import { openViewPostDlg } from '../dialogs/viewPostDlg';
import { openOrgInfo } from '../dialogs/orgInfo';
import { formateDateStr } from '../utils/utils';
import { Badges } from '../dialogs/badgeDlg';

const c_post_tag = css`
	color: black;
	font-size: 14px;
	padding: 5px;
	border-radius: 5px;
	background: lime;
	margin-right: 5px;
	white-space: nowrap;
`;

const c_badges = css`
	display: flex;
	gap: 10px;
	.badge {
		display: flex;
		align-items: center;
		border-radius: 5px;
		color: navy;
		padding: 4px;
		font-size: 14px;
	}
`;

const c_tags = css`
	font-size: 14px;
	margin-top: 4px;
	color: var(--tag-color);
	font-weight: bold;
`;

export function PostTag(p: { t: PostType }) {
	let color = PostTypeMap.get(p.t).color;
	let name = PostTypeMap.get(p.t).name;
	return (
		<div class={c_post_tag} style={{ background: color }}>
			{name}
		</div>
	);
}

export function OrgListing(p: { o: IOrg }) {
	const o = p.o;
	return (
		<div class="posting" onClick={(e) => openOrgInfo(o)}>
			<div class="flex center header">
				<PostTag t={PostType.org_info} />
				<div class="grow title">{o.name}</div>
				{o.badges.length && <Badges org_id={o.id} badges={o.badges} />}
			</div>
			<div class="desc">{o.description}</div>
			{o.tags.length > 0 && <div class={c_tags}>{o.tags.join(', ')}</div>}
		</div>
	);
}

export function Posting(z: { p: IPost; edit?: boolean }) {
	const p = z.p;
	const help_offered = p.type === PostType.help_offered;
	return (
		<div
			class="posting"
			onClick={(e) => {
				if (z.edit) openPostingDlg(p);
				else openViewPostDlg(p);
			}}
		>
			<div class="flex center header">
				<PostTag t={p.type} />
				<div class="grow title">{p.title}</div>
				<span style={{ 'font-size': '12px', 'text-align': 'right' }}>
					{formateDateStr(p.post_date)}

					{p.pending_action ? (
						<div style={{ color: 'hotpink' }}>Action Pending</div>
					) : (
						<div>actions:&nbsp;{p.action_count}</div>
					)}
					{p.complete && <div style={{ color: 'green' }}>Completed</div>}
				</span>
			</div>
			<div class="desc">{p.description}</div>
			{p.org && <div class={c_tags}>{p.org.name}</div>}
			{p.tags.length > 0 && <div class={c_tags}>{p.tags.join(', ')}</div>}
		</div>
	);
}
